<template lang="pug">
.wrap
	VitePwaManifest
	canvas.layout-canvas(ref="layoutCanvas" :style="{ background: linearBackground }")
	LayoutNav(v-if="settings && settings.content" :story="settings.content")
	.page
		slot(keep-alive)
	LayoutSingaFooter(v-if="settings && settings.content" :story="settings.content").footer
</template>

<script setup lang="ts">
const { $preview } = useNuxtApp()
const version = $preview ? 'draft' : 'published'

// Fetch the settings
const { story: settings } = await useStoryblokFetch('settings', {
	version,
	resolve_links: 'url'
})

const layoutCanvas = ref<HTMLCanvasElement | null>(null)
const customColor = ref('#12B961')

const linearBackground = computed(() => `linear-gradient(${customColor.value}, black)`)

useEventOn('layoutGradient:applyColor', (color) => { customColor.value = color })

useEventOn('layoutGradient:resetDefault', () => { customColor.value = '#12B961' })

onUnmounted(() => {
	useEventOff('layoutGradient:applyColor')
	useEventOff('layoutGradient:resetDefault')
})
</script>

<style lang="sass" scoped>
.layout-canvas
	background: linear-gradient(#052E18, black)
	height: 100vh
	width: 100%
	position: absolute
	opacity: 0.1
html
	background-color: black
.wrap
	min-height: 100vh
	display: flex
	flex-direction: column
	background-size: cover
	background-position: center
.page
	flex: 1
	position: relative
	overflow: hidden
	min-height: 100vh
.footer
	margin-top: auto
.relative-wrapper
	position: relative
.fixed-bottom
	width: 100%
	position: fixed
	bottom: 0
	left: 0
	z-index: 60
	background: black
</style>
